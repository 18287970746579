.hidden {
  display: none
}

.navigationHead {
  display: flex;
  flex-direction: row;
  background-color: tan;
}

.navigationLinksDiv {
  width: 100%;
}

.sessionLinks {
  float: right;
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.sessionLinksUnderDiv {
  margin: 0 20px;
}

.meetSup {
  display: flex;
  flex-direction: row;
  margin: 15px 0;
}

.meetSupWord {
  font-size: xx-large;
  margin-left: 10px;
  text-decoration: none;
}

.meetSupIcon {
  margin: 13px 0;
  margin-left: 10px;
}

.startNewGroupButton {
  padding: 10px 10px;
  background-color: lightcoral;
  box-shadow: 3px 3px black;
  border: 2px solid black;
  margin-right: 10px;
  cursor: pointer;
}

.startNewGroupButton:active {
  box-shadow: none;
  background-color: red;
}
