.Gname {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: x-large;
  padding-bottom: 5px;
  text-decoration: none;
  font-weight: bold;
}

.Glocation {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding-bottom: 18px;
}

.Gblurb {
  word-wrap: normal;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding-bottom: 18px;
  height: 50px;
}

.GgooksPen {
  position: relative;
  height: 120px;
}

.Ggooks {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  position: absolute;
  bottom: 0;
}
