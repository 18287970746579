.banner {
  margin-top: 100px;
  background-image: url("https://images.unsplash.com/photo-1476224203421-9ac39bcb3327?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80");
  height: 800px;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position-y: -250px; */
  object-fit: fill;
}

.bannerRuler {
  height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerWords {
  /* margin-top: 400px; */
  display: flex;
  text-align: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.616);
}

.banWords,
.banWordsTop,
.banWordsBot {
  font-size: 500%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-weight: bolder;
  color: white;
}

/* .bannerWordsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.bannerWords {
  width: fit-content;
  padding: 40px 40px;
  border-radius: 3em;
}

/* .banWordsTop {
  margin-top: 200px;
} */

/* .banWordsBot {
  margin-bottom: 228px;
} */

.featureLinks {
  margin-bottom: 50px;
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.featureEvents,
.featureGroups,
.featureCreate,
.featureCreateDisabled {
  margin: 0 25px;
  padding: 50px 50px;
  border-radius: 3em;
  background-color: tan;
}

.LPwords {
  margin-top: 20px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: brown;
  font-weight: bolder;
  font-size: x-large;
}

.LPicons {
  text-align: center;
}

.featureEvents:hover,
.featureGroups:hover,
.featureCreate:hover {
  background-color: lightgray;
}

.featureCreateDisabled {
  background-color: grey;
  color: black;
}

.joinUpButtonDiv {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.joinUpButtonActual {
  padding: 10px 10px;
  background-color: lightcoral;
  box-shadow: 3px 3px black;
  border: 2px solid black;
  cursor: pointer;
}

.joinUpButtonActual:active {
  box-shadow: none;
  background-color: red;
}


.HMWOverDiv {
  display: flex;
  justify-content: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: brown;
  margin-top: 50px;
}

.instructions {
  display: flex;
  text-align: center;
  flex-direction: row;
  justify-content: center;
}

.underInstructions {
  width: 500px;
}
