.LMfomr {
  display: flex;
  flex-direction: column;
  border-radius: 5em;
}


.LMheader {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
}

.LMformDiv {
  border-radius: 5em;
}

.LMinput,
.LMbutton {
  margin: 8px;
  padding: 5px;
}

#loginButtonModal {
  background-color: lightcoral;
  box-shadow: 3px 3px black;
  border: 2px solid black;
  padding: 10px 0;
}

#loginButtonModal:disabled {
  background-color: grey;
  box-shadow: none;
  color: black;
}

.eros {
  color: red;
  font-weight: bolder;
  text-align: center;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.demoP {
  text-align: center;
}

#demoUser {
  background: none !important;
  box-shadow: none;
  border: none;
  padding: 0 !important;
  font-family: arial, sans-serif;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: large;
}
