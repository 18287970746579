.OMB {
  padding: 10px 10px;
  background-color: lightcoral;
  box-shadow: 3px 3px black;
  border: 2px solid black;
  cursor: pointer;
}

.OMB:active {
  box-shadow: none;
  background-color: red;
}
